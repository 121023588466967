import {createIframe} from './iframe';
import {clearElementContent, elementByIdOrNode, setElementContent} from './dom';
import {setModalContent, openModal} from './modal';

const host = process.env.EMBEDDED_APP_HOST;

const getUrlParamStrFromObject = (params) => (new URLSearchParams(Object.entries(params))).toString();

class InterfaceBuilder {

    #partnerKey;

    constructor(partnerKey) {
        this.#partnerKey = partnerKey;
    }

    embedWidget(elem, params) {
        const intoNode = elementByIdOrNode(elem);

        if (!intoNode) {
            throw new Error('Unable to embed widget. Node not found.');
        }

        const pStr = getUrlParamStrFromObject(params);
        const iframe = createIframe(`${host}/ptnr/${this.#partnerKey}/embed/status-widget?${pStr}`);
        iframe.style.height = '300px';
        setElementContent(intoNode, iframe);
        return iframe;
    }

    removeWidget(elem) {
        clearElementContent(elementByIdOrNode(elem));
    }

    beginVendorRequest(workflowMode, params) {
        const pStr = getUrlParamStrFromObject({
            ...(!!params ? params : {}),
            workflowMode,
        });
        const iframe = createIframe(`${host}/ptnr/${this.#partnerKey}/embed/intake-form?${pStr}`);
        iframe.style.height = '400px';
        setModalContent(iframe);
        openModal();
    }

    getJobHelp(jobSlug) {
        const iframe = createIframe(`${host}/ptnr/${this.#partnerKey}/embed/job/${jobSlug}/help`);
        iframe.style.height = '400px';
        setModalContent(iframe);
        openModal();
    }
}

export default InterfaceBuilder;