class WidgetDataMap {
    #map = [];

    mapCallback(func, iframe) {
        this.#map.push({ func, iframe });
    }

    findData(iframe) {
        const found = this.#map.find(item => item.iframe === iframe);
        return found ? found.func : null;
    }

    removeData(iframe) {
        const i = this.#map.find(item => item.iframe === iframe);
        this.#map.splice(i, 1);
    }
}

export default WidgetDataMap;