import {setElementContent} from './dom';

const MODAL_ID = 'latchel-main-modal';
const MODAL_CONTENT_CLASS = 'js-latchel-modal-content';

const getModalElement = () => {
    let m = document.getElementById(MODAL_ID);

    // TODO prevent back button while modal open

    if (!m) {
        m = document.createElement('div');
        m.classList.add('latchel-modal');
        m.id = MODAL_ID;
        m.style.cursor = 'pointer';

        const mBody = document.createElement('div');
        mBody.classList.add('latchel-modal-body');

        const mContent = document.createElement('div');
        mContent.classList.add(MODAL_CONTENT_CLASS);

        mBody.appendChild(mContent);
        m.appendChild(mBody);

        document.body.appendChild(m);
    }

    return m;
}

const getModalContentElement = () => {
    return getModalElement().querySelector(`.${MODAL_CONTENT_CLASS}`);
}

const setModalContent = (element) => {
    const m = getModalContentElement();
    setElementContent(m, element);
}

const openModal = () => {
    getModalElement().classList.add('-open');
}

const closeModal = () => {
    getModalElement().classList.remove('-open');
}

export {
    getModalElement,
    getModalContentElement,
    openModal,
    closeModal,
    setModalContent,
}