import './styles/main.css';
import InterfaceBuilder from './InterfaceBuilder';
import {
    EVENT_BEGIN_MAINT_REQ,
    EVENT_BEGIN_JOB_HELP,
    EVENT_BODY_HEIGHT_CHANGED,
    EVENT_CLOSE_MODAL,
    EVENT_REQUEST_UPDATED,
    WORKFLOW_AFFILIATE, WORKFLOW_EMBEDDED
} from './constants';
import {closeModal} from './modal';
import WidgetDataMap from './WidgetDataMap';
import {elementByIdOrNode} from './dom';
import {getInstance, setInstance} from './SdkSingleton';
import {getIframeFromEvent, sendEventToAllOtherLatchelIframes} from './iframe';

const embedHost = process.env.EMBEDDED_APP_HOST;
const widgetDataMap = new WidgetDataMap();

const init = (partnerKey) => {
    const instance = getInstance();

    if (instance) {
        console.warn('Latchel SDK already initiated. Calling init multiple times has no effect.');
        return instance;
    }

    setInstance(new InterfaceBuilder(partnerKey));
    registerListeners(getInstance());
    return getInstance();
}

const ensureInit = () => {
    const instance = getInstance();

    if (!instance) {
        throw new Error('Latchel SDK is not initiated.');
    }

    return instance;
}

const registerListeners = (sdkInstance) => {
    window.addEventListener('message', async (e) => {
        if (e.origin !== embedHost) {
             return;
        }

        let eventData;

        try {
            eventData = JSON.parse(e.data);
        }
        catch { return; }

        const { event, detail } = eventData;
        const sourceIframe = getIframeFromEvent(e);

        switch (event) {
            case EVENT_BEGIN_MAINT_REQ:
                ensureInit();
                const wd = widgetDataMap.findData(sourceIframe);
                let reqData;

                if (wd && typeof wd === 'function') {
                    reqData = await wd(detail.referenceId);
                }
                else {
                    reqData = wd;
                }

                sdkInstance.beginVendorRequest(
                    WORKFLOW_EMBEDDED,
                    {
                        ...detail,
                        ...(!!reqData ? reqData : {}),
                    }
                );

                break;

            case EVENT_BEGIN_JOB_HELP:
                ensureInit();
                sdkInstance.getJobHelp(detail.jobSlug);
                break;

            case EVENT_CLOSE_MODAL:
                closeModal();
                break;

            case EVENT_REQUEST_UPDATED:
                sendEventToAllOtherLatchelIframes(sourceIframe, event, detail);
                break;

            case EVENT_BODY_HEIGHT_CHANGED:
                sourceIframe.style.height = `${detail.height + 10}px`;
                break;
        }
    }, false);

}

window.LatchelPartnerSDK = {
    init,
    embedStatusWidget: (elem, statusWidgetData, requestFormData) => {
        const sdkObj = ensureInit();
        const iframe = sdkObj.embedWidget(elem, statusWidgetData);

        if (requestFormData) {
            widgetDataMap.mapCallback(requestFormData, iframe);
        }
    },
    removeStatusWidget: (elem) => {
        const sdkObj = ensureInit();
        widgetDataMap.removeData(elementByIdOrNode(elem).getElementsByTagName('iframe')[0]);
        sdkObj.removeWidget(elem);
    },
};

window.LatchelAffiliateSDK = {
    startContractorRequest: (partnerKey) => {
        init(partnerKey).beginVendorRequest(WORKFLOW_AFFILIATE);
    },
}