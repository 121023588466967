const createIframe = (url) => {
    const iframe = document.createElement('iframe');

    // Set the src attribute of the iframe to the specified URL
    iframe.src = url;
    iframe.style.width = '100%';
    iframe.style.border = 'none';
    iframe.dataset.latchelFrameType = 'LATCHEL';
    iframe.classList.add('latchel-sdk-iframe');
    iframe.setAttribute('allow', 'geolocation');

    // Append the iframe to the specified element
    return iframe;
}

/**
 * Find the iframe from which the event was emitted.
 */
const getIframeFromEvent = (e) => {
    return Array
        .from(document.getElementsByTagName('iframe'))
        .filter(iframe => {
            return iframe.contentWindow === e.source;
        })[0];
}

const findLatchelIframes = () => {
    return document.querySelectorAll(`iframe[data-latchel-frame-type="LATCHEL"]`);
};

const sendEventToIframe = (iframe, event, detail) => {
    iframe.contentWindow.postMessage(JSON.stringify({
        event,
        detail,
        latchelSource: 'SDK',
    }), '*');
};

/**
 * Send an event to all latchel iframes that are not the sourceIframe
 */
const sendEventToAllOtherLatchelIframes = (sourceIframe, event, detail) => {
    findLatchelIframes().forEach(iframe => {
        if (iframe !== sourceIframe) {
            sendEventToIframe(iframe, event, detail);
        }
    });
}

export {
    createIframe,
    getIframeFromEvent,
    sendEventToAllOtherLatchelIframes,
};
