

const clearElementContent = (elem) => {
    while (elem.firstChild) {
        elem.removeChild(elem.firstChild);
    }

    return elem;
}

const setElementContent = (elem, content) => {
    clearElementContent(elem).appendChild(content);
}

/**
 * If elem is a string, find it by id. Otherwise, it must be a node. Either way, the node is returned.
 * @param elem
 */
const elementByIdOrNode = (elem) => {
    if (typeof elem === 'string') {
        return document.getElementById(elem);
    }

    return elem;
}

export {
    setElementContent,
    clearElementContent,
    elementByIdOrNode,
}