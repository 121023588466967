const EVENT_BEGIN_MAINT_REQ = 'LATCHEL:BEGIN-MAINT-REQ';
const EVENT_BEGIN_JOB_HELP = 'LATCHEL:BEGIN-JOB-HELP';
const EVENT_CLOSE_MODAL = 'LATCHEL:OPEN-CLOSE';
const EVENT_REQUEST_UPDATED = 'LATCHEL:VENDOR-REQUEST-UPDATED';
const EVENT_BODY_HEIGHT_CHANGED = 'LATCHEL:BODY-HEIGHT-CHANGE';
const WORKFLOW_EMBEDDED = 'embed';
const WORKFLOW_AFFILIATE = 'guest';

export {
    EVENT_CLOSE_MODAL,
    EVENT_BEGIN_MAINT_REQ,
    EVENT_BEGIN_JOB_HELP,
    EVENT_REQUEST_UPDATED,
    EVENT_BODY_HEIGHT_CHANGED,
    WORKFLOW_EMBEDDED,
    WORKFLOW_AFFILIATE,
};