/**
 * @type {null|InterfaceBuilder}
 */
let instance = null;

const setInstance = (i) => {
    instance = i;
    return instance;
}

const getInstance = () => {
    return instance;
}

export {
    setInstance,
    getInstance,
}